const NotFound = () => {
  return (
    <>
      <div>
        <h2>Page Not Found</h2>
      </div>
    </>
  );
};

export default NotFound;
