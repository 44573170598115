const Footer = () => {
  return (
    <div className="footer-container" >
      <h1>TIDI Mobili</h1>
      <p>Todos los derechos reservados</p>
    </div>
  );
};

export default Footer;
