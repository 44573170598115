const Loading = () => {
  return (
    <>
      <div>
        <h2>Loading</h2>
      </div>
    </>
  );
};

export default Loading;
